var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { datasyncService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var DepartmentInfoService = /** @class */ (function () {
    function DepartmentInfoService() {
    }
    /**
     * 手工同步机构
     */
    DepartmentInfoService.prototype.handleDepartment = function (loading) {
        return this.netService.send({
            server: datasyncService.departmentInfoController.handleDepartment,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], DepartmentInfoService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], DepartmentInfoService.prototype, "handleDepartment", null);
    return DepartmentInfoService;
}());
export { DepartmentInfoService };
