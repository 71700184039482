import { render, staticRenderFns } from "./batch-allot-role.vue?vue&type=template&id=8fa4a134&scoped=true"
import script from "./batch-allot-role.vue?vue&type=script&lang=ts"
export * from "./batch-allot-role.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fa4a134",
  null
  
)

export default component.exports