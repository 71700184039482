var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { UserDeviceService } from "~/services/manage-service/user-device.service";
var FacilityManage = /** @class */ (function (_super) {
    __extends(FacilityManage, _super);
    function FacilityManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userObj = {};
        _this.dataSet = [];
        return _this;
    }
    FacilityManage.prototype.refresh = function (obj) {
        var _this = this;
        this.userObj = obj;
        // 获取所有设备
        this.userDeviceService.findAllUserDevice(obj.id).subscribe(function (data) {
            _this.dataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 启用禁用设备
     */
    FacilityManage.prototype.hangleFacility = function (data, status) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5BF9\u7528\u6237" + this.userObj.username + "\u6267\u884C\u8BE5\u64CD\u4F5C\u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            var hangleFacility = {
                id: data.id,
                status: status
            };
            _this.userDeviceService.modifyStatus(hangleFacility).subscribe(function (data) {
                // 更新数据信息
                _this.$message.success("操作成功");
                _this.refresh(_this.userObj);
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    /**
     * 重置设备
     */
    FacilityManage.prototype.resetfacility = function (data) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5BF9\u7528\u6237" + this.userObj.username + "\u6267\u884C\u8BE5\u64CD\u4F5C\u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.userDeviceService.deleteUserDevice(data.id).subscribe(function (data) {
                // 更新数据信息
                _this.$message({
                    type: "success",
                    message: "操作成功!"
                });
                _this.refresh(_this.userObj);
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    __decorate([
        Dependencies(UserDeviceService)
    ], FacilityManage.prototype, "userDeviceService", void 0);
    FacilityManage = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], FacilityManage);
    return FacilityManage;
}(Vue));
export default FacilityManage;
